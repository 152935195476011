export type BasicDefinition = {
    /**
     * Name of the component.
     */
    name: string;
};

/**
 * Replaces {color: ColorOptions} with {$color: ColorOptions} (so styled-components can strip it).
 */
export type ReplaceColorToSC<Props, Additional = object> = Omit<Props, 'color'> & {
    $color: 'color' extends keyof Props ? Required<Props>['color'] : never;
} & Additional;

export type IGNORECHECKSType = {
    /** @deprecated Please don't use this, contact your designer or hammer developers */
    IGNORECHECKS?: 'confirm';
};

export type DataTestId = {
    /**
     * Add an attribute to a node to help make selecting easier during E2E tests. LA's data attribute of choice.
     *
     * @see https://developer.mozilla.org/en-US/docs/Learn/HTML/Howto/Use_data_attributes
     */
    'data-testid'?: string;
};

export type BasicStyledComponentsProps = DataTestId & {
    /**
     * Access to internal className. Usually, a `styled(...)` component takes care of this, so wrap this component with that.
     *
     * Note: You cannot use this and the provided mixins at once.
     * You can readd them with the `allMixin` export.
     * See their docs [here](https://hammer-ui-prod.liveauctioneers.com/?path=/docs/dev-docs-css-mixins--component-docs) for more details
     *
     * Usage:
     * ```tsx
     * const ExampleComponent = () => {
     *     return <StyledComponent />;
     * }
     * // ... outside the component
     * const StyledComponent = styled(Component)``;
     * ```
     */
    className?: string;
};

export type ColorOptionsExtension<ColorOptions extends string = string> = {
    /**
     * What the available list of colors is.
     */
    colorOptions: ColorOptions[];
};

/**
 * Picks a valid color option from `colorOptions` (per definition) and `extraColorOptions` (per component)
 * or if none is found, a sensible default.
 *
 * @param colorOptions Viable color options that will be selected should `color` equal any of them.
 * @param name The name of the component that is calling this function for better errors.
 * @param color The user / developer selected color.
 * @param componentText The name of the factory that is calling this function for better errors.
 * @param IGNORECHECKS Ignores the function of this function and just blindly accepts the color.
 * @param extraColorOptions Viable color options that will be selected should `color` equal any of them.
 * @returns Probably validated color.
 */
export const checkColor = <ColorOptions extends string>(
    colorOptions: string[] = [],
    name: string,
    color: ColorOptions | undefined,
    componentText: string,
    IGNORECHECKS?: IGNORECHECKSType['IGNORECHECKS'],
    extraColorOptions?: string[]
) => {
    if (Boolean(color)) {
        if (process.env.NODE_ENV === 'production') {
            return color;
        }
        if (process.env.NODE_ENV !== 'production') {
            if (
                (color && colorOptions.includes(color)) ||
                (color && extraColorOptions && extraColorOptions.includes(color)) ||
                IGNORECHECKS === 'confirm'
            ) {
                return color;
            }
            throw new Error(
                `Invalid color given (${color}) / unsupported by ${componentText} component "${name}". Options include: ${colorOptions.join(
                    ', '
                )}`
            );
        }
    }
};
