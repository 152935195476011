/* eslint-disable sort-keys/sort-keys-fix */
import { type Theme } from './themeType';

export const spacing = {
    xxxs: '2px',
    xxxsValue: 2,
    xxs: '4px',
    xxsValue: 4,
    xs: '8px',
    xsValue: 8,
    sm: '16px',
    smValue: 16,
    md: '24px',
    mdValue: 24,
    lg: '32px',
    lgValue: 32,
    xl: '48px',
    xlValue: 48,
    xxl: '64px',
    xxlValue: 64,
    xxxl: '128px',
    xxxlValue: 128,
} as const satisfies Theme['spacing'];

export const breakpoints = {
    xsMax: '768px',
    xsMaxValue: 768,
    smMin: '769px',
    smMinValue: 769,
    smMax: '1023px',
    smMaxValue: 1023,
    mdMin: '1024px',
    mdMinValue: 1024,
    mdMax: '1248px',
    mdMaxValue: 1248,
    lgMin: '1249px',
    lgMinValue: 1249,
    lgMax: '1644px',
    lgMaxValue: 1644,
    xlMin: '1645px',
    xlMinValue: 1645,
} as const satisfies Theme['breakpoints'];

export const radius = {
    zero: '0',
    xs: '2px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    xl: '32px',
    circle: '50%',
} as const satisfies Theme['border']['radius'];

export const width = {
    xxs: '0.5px',
    xs: '1px',
    sm: '2px',
    md: '4px',
    lg: '8px',
} as const satisfies Theme['border']['width'];
