import { AnyIcon } from 'src/icons/v1/anyIcon';
import { BaseButton, ButtonLoading, LoadingProp } from './baseButton';
import styled, { css } from 'styled-components';

export const SecondaryAltButton = styled(BaseButton)<LoadingProp>`
    ${({ $loading, theme }) =>
        $loading
            ? css`
                  background-color: ${theme.button.secondary.alt.pressed.color.background};
                  color: ${theme.button.secondary.alt.pressed.color.background};
                  outline-color: ${theme.button.secondary.alt.pressed.color.border};

                  ${ButtonLoading} {
                      color: ${theme.button.secondary.alt.color.text};
                  }
              `
            : css`
                  color: ${theme.button.secondary.alt.color.text};
                  background-color: ${theme.button.secondary.alt.color.background};
                  outline-color: ${theme.button.secondary.alt.color.border};

                  &:not(:hover):not(:active):not(:disabled):focus-visible {
                      background-color: ${theme.button.secondary.alt.focused.color.background};
                      color: ${theme.button.secondary.alt.focused.color.text};
                  }

                  &:not(:disabled):active {
                      background-color: ${theme.button.secondary.alt.pressed.color.background};
                      outline-color: ${theme.button.secondary.alt.pressed.color.border};
                      color: ${theme.button.secondary.alt.hover.color.text};
                  }

                  &:not(:disabled):not(:active):hover {
                      background-color: ${theme.button.secondary.alt.hover.color.background};
                      outline-color: ${theme.button.secondary.alt.hover.color.border};
                      color: ${theme.button.secondary.alt.hover.color.text};
                  }

                  &:disabled {
                      outline-color: ${theme.color.border.disabled};
                  }

                  &:disabled > ${AnyIcon} {
                      color: ${theme.color.icon.disabled};
                  }

                  &:not(:disabled) > ${AnyIcon} {
                      color: ${theme.color.icon.primary.default};
                  }
              `}
`;
