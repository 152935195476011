import { SingleSplit, Split } from './utilityTypes';

export type FilterNonObjectProperties<T> = Pick<
    T,
    {
        [K in keyof T]-?: T[K] extends object ? K : never;
    }[keyof T]
>;

export type FilterObjectProperties<T> = Pick<
    T,
    {
        [K in keyof T]-?: T[K] extends object ? never : K;
    }[keyof T]
>;

type NextValue<K, R> = K extends string | number ? (R extends string ? `${K}.${R}` : never) : never;

type SetupNextExpand<T, KeepObjectProperties extends boolean, E = FilterNonObjectProperties<T>> = T extends object
    ? {
          [K in keyof E]: NextValue<K, ExpandNestedObjectToStringUnion<E[K], KeepObjectProperties>>;
      }
    : never;

export type ExpandNestedObjectToStringUnion<
    O,
    KeepObjectProperties extends boolean = false,
    Recur = SetupNextExpand<O, KeepObjectProperties>,
> = O extends object
    ? keyof (KeepObjectProperties extends true ? O : FilterObjectProperties<O>) | Recur[keyof Recur]
    : never;

export type InferObjectTypeFromString<O, Path extends string, S = Split<Path, '.'>> = S extends [infer K, ...infer R]
    ? K extends keyof O
        ? R[0] extends undefined
            ? O[K]
            : InferObjectTypeFromString<O[K], '', R>
        : never
    : never;

export type NestedRecord<Keys extends string, Value, SplitKeys = SingleSplit<Keys, '.'>> = SplitKeys extends [
    infer K,
    infer R,
]
    ? K extends string
        ? R extends string
            ? Record<K, NestedRecord<R, Value>>
            : never
        : never
    : Record<Keys, Value>;

export const objectStringReduction = <O, Path extends string>(
    obj: O,
    path: Path
): InferObjectTypeFromString<O, Path> => {
    return path.split('.').reduce((o: any, i) => o[i], obj);
};
