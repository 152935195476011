import { iconFactory } from './iconFactory';

/**
 * @deprecated See changelog to convert to phosphor icons
 */
export const SpinnerIconExtraSmall = iconFactory({
    name: 'SpinnerIconExtraSmall',
    size: 'ExtraSmall',
    svg: (
        <path d="M10.9147 9.25996L10.3719 8.94658C10.2315 8.86549 10.1756 8.69002 10.2452 8.54354C10.7495 7.48244 10.7055 6.22709 10.113 5.20311C9.52193 4.17715 8.45676 3.51129 7.2857 3.41748C7.12402 3.40453 7 3.26842 7 3.10623V2.4795C7 2.29766 7.15461 2.1551 7.33602 2.16778C8.93357 2.27944 10.3909 3.18149 11.1956 4.57809C12.0023 5.97233 12.0548 7.68543 11.3527 9.12481C11.2729 9.28826 11.0722 9.35088 10.9147 9.25996Z" />
    ),
    svgElements: ['path'],
});

/**
 * @deprecated See changelog to convert to phosphor icons
 */
export const SpinnerIconSmall = iconFactory({
    name: 'SpinnerIconSmall',
    size: 'Small',
    svg: (
        <path d="M16.2635 13.6163L15.3951 13.1149C15.1703 12.9852 15.081 12.7044 15.1924 12.4701C15.9993 10.7723 15.9288 8.76374 14.9809 7.12536C14.0351 5.48383 12.3308 4.41846 10.4571 4.26836C10.1984 4.24765 10 4.02986 10 3.77036V2.76758C10 2.47665 10.2474 2.24855 10.5376 2.26883C13.0937 2.44749 15.4255 3.89077 16.713 6.12533C18.0036 8.35611 18.0877 11.0971 16.9643 13.4001C16.8367 13.6616 16.5155 13.7618 16.2635 13.6163Z" />
    ),
    svgElements: ['path'],
});
