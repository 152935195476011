import { atom, useAtomValue, useStore } from 'jotai';
import { breakpoints } from 'src/theme/common';
import { isClientSide } from 'src/utils';
import { ReactNode, useEffect, useMemo } from 'react';
import { useThrottledCallback } from 'use-debounce';

// the following are not true for shadow doms
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
export const heightAtom = atom(isClientSide && document.body ? document.body.clientHeight : 0);
// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/strict-boolean-expressions
export const widthAtom = atom(isClientSide && document.body ? document.body.clientWidth : 0);

export const desktopAtom = atom((get) => get(widthAtom) >= breakpoints.smMinValue);
export const mobileAtom = atom((get) => get(widthAtom) <= breakpoints.xsMaxValue);
export const tabletAtom = atom(
    (get) => get(widthAtom) >= breakpoints.smMinValue && get(widthAtom) <= breakpoints.smMaxValue
);
export const tabletOrMobileAtom = atom((get) => get(widthAtom) <= breakpoints.smMaxValue);

type Props = {
    children: ReactNode;
    /**
     * Only applies to first render, gives the default value for the height of the screen. Useful for SSR. (You *must* set it on the first render)
     *
     * @default document.body.clientHeight ?? 0
     */
    defaultHeight?: number;
    /**
     * Only applies to first render, gives the default value for the width of the screen. Useful for SSR. (You *must* set it on the first render)
     *
     * @default document.body.clientWidth ?? 0
     */
    defaultWidth?: number;
    /**
     * How often to refresh the window when resizing it.
     *
     * Set this to milliseconds to wait, or, `0` is per animation frame.
     *
     * @default 0
     * @see https://github.com/xnimorz/use-debounce#usethrottledcallback
     */
    windowSizeThrottle?: number;
};

export const WindowSizeProvider = ({
    children,
    defaultHeight: givenDefaultHeight,
    defaultWidth: givenDefaultWidth,
    windowSizeThrottle = 0,
}: Props) => {
    const store = useStore();

    useMemo(() => {
        if (store.get(heightAtom) === 0 && givenDefaultHeight) {
            store.set(heightAtom, givenDefaultHeight);
        }
        if (store.get(widthAtom) === 0 && givenDefaultWidth) {
            store.set(widthAtom, givenDefaultWidth);
        }

        // No need to rerender since we only want to allow initial
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (store.get(heightAtom) !== document.body.clientHeight && document.body.clientHeight !== 0) {
            store.set(heightAtom, document.body.clientHeight);
        }
        if (store.get(widthAtom) !== document.body.clientWidth && document.body.clientWidth !== 0) {
            store.set(widthAtom, document.body.clientWidth);
        }
    }, [store]);

    const handleResize = useThrottledCallback(
        () => {
            store.set(heightAtom, document.body.clientHeight);
            store.set(widthAtom, document.body.clientWidth);
        },
        windowSizeThrottle,
        { trailing: true }
    );

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        window.addEventListener('orientationchange', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('orientationchange', handleResize);
        };
    }, [handleResize]);

    return children;
};

export const useWindowIsDesktop = () => useAtomValue(desktopAtom);
export const useWindowIsMobile = () => useAtomValue(mobileAtom);
export const useWindowIsTablet = () => useAtomValue(tabletAtom);
export const useWindowIsTabletOrMobile = () => useAtomValue(tabletOrMobileAtom);
export const useWindowHeight = () => useAtomValue(heightAtom);
export const useWindowWidth = () => useAtomValue(widthAtom);
