import { BasicStyledComponentsProps } from 'src/utils/factories';
import { pageGrid_CSS } from './pageGrid';
import { useEffect, useState } from 'react';
import { useWindowIsMobile } from 'src/providers/windowSizeProvider';
import styled from 'styled-components';

// Only declared here since theoretically nothing should ever touch this itself.
declare global {
    interface Window {
        hui?: {
            enableGridHighlights?: (setting: boolean) => void;
        };
    }
}

type Props = BasicStyledComponentsProps & {
    /** Whether to enable the grid highlighter. Probably don't want to use this prop, designed for storybook. If you want to turn it on, simply run `hui.enableGridHighlights(true)` in the dev console */
    forceGridHighlight?: boolean;
};

export const GridHighlighter = ({ forceGridHighlight, ...rest }: Props) => {
    const [highlightGrid, setGridHighlight] = useState(false);

    useEffect(() => {
        if (
            typeof window !== 'undefined' &&
            Boolean(window) &&
            (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test')
        ) {
            window.hui = window.hui ?? {};

            window.hui.enableGridHighlights = (setting: boolean) => {
                if (typeof setting === 'boolean') {
                    setGridHighlight(setting);
                }
            };

            return () => {
                // Never hurts to be careful with window
                // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
                if (typeof window !== 'undefined' && window.hui) {
                    delete window.hui.enableGridHighlights;
                }
            };
        }
    });

    const mobile = useWindowIsMobile();

    if (highlightGrid || forceGridHighlight) {
        return (
            <StyledGridHighlighter
                data-testid="grid-highlighter"
                {...rest}
            >
                {[...Array(mobile ? 4 : 12).keys()].map((i) => (
                    <div
                        data-testid="grid-highlighter-column"
                        key={i}
                    />
                ))}
            </StyledGridHighlighter>
        );
    }

    return null;
};

export const StyledGridHighlighter = styled.div`
    ${pageGrid_CSS}
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: transparent;
    z-index: 1;
    pointer-events: none;
    > div {
        pointer-events: none;
        background-color: ${({ theme }) => theme.color.text.error};
        opacity: 0.15;
    }
`;
