import { type CleanTheme } from 'src/theme/themeType';
import { css } from 'styled-components';
import { ExpandNestedObjectToStringUnion, Theme } from 'src/types';
import { Replace } from 'src/utils/utilityTypes';

// ExpandNestedObjectToStringUnion<Theme['spacing']>

export type LocationProp = { $gridLocation?: string };

export const locationMixin = css<LocationProp>`
    ${({ $gridLocation }) =>
        $gridLocation &&
        css`
            grid-area: ${$gridLocation};
        `}
`;

export type LineClampProp = { $lineClamp?: number };

export const lineClampMixin = css<LineClampProp>`
    ${({ $lineClamp }) =>
        $lineClamp &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: ${$lineClamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
        `}
`;

export type PaddingProps = {
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $padding?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $paddingBottom?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $paddingLeft?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $paddingRight?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $paddingTop?: string;
};

export type MarginProps = {
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $margin?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $marginBottom?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $marginLeft?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $marginRight?: string;
    /**
     * String used for formatting.
     *
     * Quick reference (px): `xxxs(2)` `xxs(4)` `xs(8)` `sm(16)` `md(24)` `lg(32)` `xl(48)` `xxl(64)` `xxxl(128)`
     *
     * If it matches a key in `theme.spacing`, it will be that spacing. IE
     * if set to `sm` it would have the value of `theme.spacing.sm`
     *
     * If it matches multiple keys in `theme.spacing` separated by a + or -,
     * it will properly mutate the size. IE if set to `sm+md` it would have the
     * value of `theme.spacing.smValue + theme.spacing.mdValue` (pixels).
     *
     * Otherwise, it will directly pass this to the relevant CSS property.
     */
    $marginTop?: string;
};

type ThemeSpacings = ExpandNestedObjectToStringUnion<Theme['spacing']>;
export type SimplifiedThemeSpacings = Replace<ThemeSpacings, 'Value', ''>;

// Memoized, matches the types, so should be accurate.
const themeKeys: SimplifiedThemeSpacings[] = ['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs', 'xxxs'];

const splitRegex = /([+-])/;

const handleSplitStyles = (spacing: CleanTheme['spacing'], splitStyles: string[], styles: string) => {
    // Remove whitespace so "sm + xl" works.
    const trimmedFirstValue = splitStyles[0].trim();

    // If it's not in the theme spacings, it cannot be parsed.
    if (!themeKeys.includes(trimmedFirstValue as SimplifiedThemeSpacings)) {
        return styles;
    }

    // Get first value
    let acc = spacing[(trimmedFirstValue + 'Value') as ThemeSpacings] as number;

    // Check the rest of the values
    for (let i = 2; i < splitStyles.length; i += 2) {
        // Remove whitespace so "sm + xl" works.
        const trimmedValue = splitStyles[i].trim();

        // If it's not in the theme spacings, it cannot be parsed.
        if (!themeKeys.includes(trimmedValue as SimplifiedThemeSpacings)) {
            return styles;
        }

        // Add the proper value
        if (i % 2 === 0) {
            if (splitStyles[i - 1] === '+') {
                acc += spacing[(trimmedValue + 'Value') as ThemeSpacings] as number;
            } else {
                acc -= spacing[(trimmedValue + 'Value') as ThemeSpacings] as number;
            }
        }
    }

    // Return accumulated value
    return `${acc}px`;
};

export const themeSpacingParser = (spacing: CleanTheme['spacing'], styles: string) => {
    if (themeKeys.includes(styles as SimplifiedThemeSpacings)) {
        return spacing[styles as SimplifiedThemeSpacings];
    }

    // Handle the logic for sm+md-xl, etc.
    const splitStyles = styles.split(splitRegex);

    if (splitStyles.length > 1) {
        return handleSplitStyles(spacing, splitStyles, styles);
    }

    // Pass through if no replacements work.
    return styles;
};

export const paddingMixin = css<PaddingProps>`
    ${({ $padding, theme }) =>
        $padding &&
        $padding.length > 0 &&
        css`
            padding: ${themeSpacingParser(theme.spacing, $padding)};
        `}
    ${({ $paddingBottom, theme }) =>
        $paddingBottom &&
        $paddingBottom.length > 0 &&
        css`
            padding-bottom: ${themeSpacingParser(theme.spacing, $paddingBottom)};
        `}
    ${({ $paddingLeft, theme }) =>
        $paddingLeft &&
        $paddingLeft.length > 0 &&
        css`
            padding-left: ${themeSpacingParser(theme.spacing, $paddingLeft)};
        `}
    ${({ $paddingRight, theme }) =>
        $paddingRight &&
        $paddingRight.length > 0 &&
        css`
            padding-right: ${themeSpacingParser(theme.spacing, $paddingRight)};
        `}
    ${({ $paddingTop, theme }) =>
        $paddingTop &&
        $paddingTop.length > 0 &&
        css`
            padding-top: ${themeSpacingParser(theme.spacing, $paddingTop)};
        `}
`;
export const marginMixin = css<MarginProps>`
    ${({ $margin, theme }) =>
        $margin &&
        $margin.length > 0 &&
        css`
            margin: ${themeSpacingParser(theme.spacing, $margin)};
        `}
    ${({ $marginBottom, theme }) =>
        $marginBottom &&
        $marginBottom.length > 0 &&
        css`
            margin-bottom: ${themeSpacingParser(theme.spacing, $marginBottom)};
        `}
    ${({ $marginLeft, theme }) =>
        $marginLeft &&
        $marginLeft.length > 0 &&
        css`
            margin-left: ${themeSpacingParser(theme.spacing, $marginLeft)};
        `}
    ${({ $marginRight, theme }) =>
        $marginRight &&
        $marginRight.length > 0 &&
        css`
            margin-right: ${themeSpacingParser(theme.spacing, $marginRight)};
        `}
    ${({ $marginTop, theme }) =>
        $marginTop &&
        $marginTop.length > 0 &&
        css`
            margin-top: ${themeSpacingParser(theme.spacing, $marginTop)};
        `}
`;

export const genericCSSMixins = css`
    ${locationMixin};
    ${paddingMixin};
    ${marginMixin};
`;

export type GenericCSSMixinProps = LocationProp & PaddingProps & MarginProps;
