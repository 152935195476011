import { css } from 'styled-components';

/**
 * Basic transition that we use across HUI.
 */
export const baseTransitionStyle = '200ms ease';

/**
 * Simply transitions everything (literally `all`) with `baseTransitionStyle`.
 *
 * Usage:
 * ```tsx
 * styled.div`
 *     ${baseTransitionStyle};
 * `
 * ```
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Transitions/Using_CSS_transitions
 */
export const allTransition = css`
    transition: all ${baseTransitionStyle};
`;

/**
 * Transitions every style you pass to the array with `baseTransitionStyle`.
 *
 * Usage:
 * ```tsx
 * styled.div`
 *     ${specificTransition('color', 'border-radius')};
 * `
 * ```
 *
 * @param styles Array of style names.
 * @returns Appropriate CSS for the transitions
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Transitions/Using_CSS_transitions
 */
export const specificTransition = (...styles: string[]) => css`
    transition: ${styles.map((style) => `${style} ${baseTransitionStyle}`).join(', ')};
`;
