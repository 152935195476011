import { type CleanTheme } from '../theme/themeType';
import { ComponentProps, ReactNode, StrictMode } from 'react';
import { GlobalNormalize, LocalNormalize } from './normalize';
import { GridHighlighter } from '../grid/gridHighlighter';
import { LinkProvider } from './linkProvider';
import { ModalTarget } from 'src/modal/components/ModalTarget/ModalTarget';
import { Provider } from 'jotai';
import { ThemeProvider } from 'styled-components';
import { WindowSizeProvider } from './windowSizeProvider';

type HammerUIProviderProps = {
    children: ReactNode;
    /**
     * Where to apply the normalization, either globally or to all styled components.
     *
     * Globally is preferred
     *
     * scoped is useful when integrating Hammer UI components
     * into an existing app (missing the current reset)
     */
    normalizationType?: 'global' | 'scoped';
    /**
     * Adds a `LinkProvider` if passed, can be overrwritten by a more specific context
     */
    onLinkClick?: (href: string) => void;
    /**
     * How to add a theme to `HammerUIProvider`.
     *
     * Usage:
     *
     * ```tsx
     * import { bidspotterTheme } from '@liveauctioneers/hammer-ui-core/theme/bidspotter';
     * import { liveauctioneersTheme } from '@liveauctioneers/hammer-ui-core/theme/liveauctioneers';
     * import { proxibidTheme } from '@liveauctioneers/hammer-ui-core/theme/proxibid';
     * import { wavebidTheme } from '@liveauctioneers/hammer-ui-core/theme/wavebid';
     * import { theSaleroomTheme } from '@liveauctioneers/hammer-ui-core/theme/theSaleroom';
     * import { lotTissimoTheme } from '@liveauctioneers/hammer-ui-core/theme/lotTissimo';
     * import { HammerUIProvider } from '@liveauctioneers/hammer-ui-core/providers';
     *
     * // If you need to modify a theme (merge in another styled components theme),
     * // you would do that before you pass to the provider
     *
     * <HammerUIProvider theme={bidspotterTheme}>...</HammerUIProvider>
     * // or
     * <HammerUIProvider theme={liveauctioneersTheme}>...</HammerUIProvider>
     * // or
     * <HammerUIProvider theme={proxibidTheme}>...</HammerUIProvider>
     * // or
     * <HammerUIProvider theme={wavebidTheme}>...</HammerUIProvider>
     * // or
     * <HammerUIProvider theme={theSaleroomTheme}>...</HammerUIProvider>
     * // or
     * <HammerUIProvider theme={lotTissimoTheme}>...</HammerUIProvider>
     * ```
     */
    theme?: CleanTheme;
    /**
     * Disable the grid highlighter.
     */
    withoutHighlighter?: boolean;
    /**
     * Whether to disable the `jotai` `Provider`. You will need to provide it manually.
     */
    withoutJotaiProvider?: boolean;
    /**
     * Whether to remove ModalTarget from the DOM. You will need to provide it manually.
     */
    withoutModalTarget?: boolean;
    /**
     * Disable normalization CSS
     */
    withoutNormalization?: boolean;
    /**
     * Whether to disable the `styled-components` `ThemeProvider`. You will need to provide it manually.
     */
    withoutThemeProvider?: boolean;
    /**
     * Whether to disable the `WindowSizeProvider`. You will need to provide it manually.
     */
    withoutWindowSizeProvider?: boolean;
};

type Props = HammerUIProviderProps &
    Partial<ComponentProps<typeof WindowSizeProvider>> &
    Partial<ComponentProps<typeof GridHighlighter>>;

/**
 * Wrapper for all Hammer UI components.
 *
 * Features:
 * - `ThemeProvider`
 * - `Normalization` (see `normalizationType` and `withoutNormalization` props)
 * - `WindowSizeProvider`
 * - `GridSizeProvider`
 * - `GridHighlighter`
 * - `React.StrictMode` when not testing
 */
export const HammerUIProvider = ({
    children,
    defaultHeight,
    defaultWidth,
    forceGridHighlight,
    normalizationType = 'global',
    onLinkClick,
    theme,
    windowSizeThrottle,
    withoutHighlighter,
    withoutJotaiProvider,
    withoutModalTarget = process.env.NODE_ENV === 'test',
    withoutNormalization,
    withoutThemeProvider,
    withoutWindowSizeProvider,
}: Props) => {
    let newChildren = children;

    if (process.env.NODE_ENV !== 'test') {
        newChildren = <StrictMode>{children}</StrictMode>;
    }

    if (!withoutNormalization && normalizationType === 'scoped') {
        newChildren = <LocalNormalize>{children}</LocalNormalize>;
    }

    if (!withoutHighlighter) {
        newChildren = (
            <>
                <GridHighlighter forceGridHighlight={forceGridHighlight} />
                {newChildren}
            </>
        );
    }

    if (!withoutWindowSizeProvider) {
        newChildren = (
            <WindowSizeProvider
                defaultHeight={defaultHeight}
                defaultWidth={defaultWidth}
                windowSizeThrottle={windowSizeThrottle}
            >
                {newChildren}
            </WindowSizeProvider>
        );
    }

    if (!withoutNormalization && normalizationType === 'global') {
        newChildren = (
            <>
                <GlobalNormalize />
                {newChildren}
            </>
        );
    }

    if (!withoutModalTarget) {
        newChildren = (
            <>
                <ModalTarget />
                {newChildren}
            </>
        );
    }

    if (!withoutThemeProvider) {
        // This will error as expected if no theme is passed
        newChildren = <ThemeProvider theme={theme as any}>{newChildren}</ThemeProvider>;
    }

    if (onLinkClick) {
        newChildren = <LinkProvider onLinkClick={onLinkClick}>{newChildren}</LinkProvider>;
    }

    // Jotai provider should likely always be last
    if (!withoutJotaiProvider) {
        newChildren = <Provider>{newChildren}</Provider>;
    }

    return <>{newChildren}</>;
};
