import { atom, useAtomValue } from 'jotai';
import { breakpoints } from 'src/theme/common';
import { mobileAtom, widthAtom } from './windowSizeProvider';

// Default is based on desktop big window.
const gridSizeAtom = atom<{
    columnCount: number;
    columnSize: number;
    gapSize: number;
}>((get) => {
    const width = get(widthAtom);
    const mobile = get(mobileAtom);

    return mobile
        ? { columnCount: 4, columnSize: (width - 16 * 5) / 4, gapSize: 16 }
        : {
              columnCount: 12,
              columnSize: (Math.min(width, breakpoints.mdMaxValue) - 24 * 13) / 12,
              gapSize: 24,
          };
});

/**
 * Provides access to three values
 * ```tsx
 * columnCount: 4 | 12
 * columnSize: number
 * gapSize: 16 | 24
 * ```
 */
export const useGridSize = () => useAtomValue(gridSizeAtom);
