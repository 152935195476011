import { css } from 'styled-components';

export const interactionStatesMixin = ({
    active,
    activePrefix = '&:active',
    base,
    disabled,
    disabledPrefix,
    focus,
    focusPrefix = '&:focus',
    hover,
    hoverPrefix = '&:hover',
    isDisabled,
    keyboardFocus,
    keyboardFocusPrefix = '&:focus-visible',
    removeStyles,
}: {
    active?: false | ReturnType<typeof css>;
    activePrefix?: string;
    base?: false | ReturnType<typeof css>;
    disabled?: false | ReturnType<typeof css>;
    disabledPrefix?: string;
    focus?: false | ReturnType<typeof css>;
    focusPrefix?: string;
    hover?: false | ReturnType<typeof css>;
    hoverPrefix?: string;
    isDisabled?: boolean;
    keyboardFocus?: false | ReturnType<typeof css>;
    keyboardFocusPrefix?: string;
    removeStyles?: boolean;
}) => {
    if (removeStyles) {
        return '';
    }

    return css`
        ${isDisabled && disabled
            ? disabled
            : css`
                  ${base};

                  ${focusPrefix &&
                  focus &&
                  css`
                      ${focusPrefix} {
                          ${focus};
                      }
                  `}

                  ${keyboardFocusPrefix &&
                  keyboardFocus &&
                  css`
                      ${keyboardFocusPrefix} {
                          ${keyboardFocus};
                      }
                  `}

                ${hoverPrefix &&
                  hover &&
                  css`
                      ${hoverPrefix} {
                          ${hover};
                      }
                  `}

                ${activePrefix &&
                  active &&
                  css`
                      ${activePrefix} {
                          ${active};
                      }
                  `}

                ${disabledPrefix &&
                  disabled &&
                  css`
                      ${disabledPrefix} {
                          ${disabled};
                      }
                  `}
              `}
    `;
};
