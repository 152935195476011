import styled, { createGlobalStyle, css } from 'styled-components';

const sharedNormalization = css`
    *,
    ::before,
    ::after {
        box-sizing: border-box;
    }
`;

export const LocalNormalize = styled.div`
    -webkit-text-size-adjust: 100%;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    ${sharedNormalization};
`;

export const GlobalNormalize = createGlobalStyle<{ normalizationType?: 'global' | 'styled-components' }>`
    html {
        -webkit-font-smoothing : antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    body {
        margin: 0;
    }
    main {
        display: block;
    }
    a {
        background-color: transparent;
    }
    img {
        border-style: none;
    }
    button,
    input,
    optgroup,
    select,
    textarea {
        font-family: inherit;
        font-size: 100%;
        line-height: 1.15;
        margin: 0;
    }
    button,
    input {
        overflow: visible;
    }
    button,
    select {
        text-transform: none;
    }
    button,
    [type=\"button\"],
    [type=\"reset\"],
    [type=\"submit\"] {
        -webkit-appearance: button;
    }
    button::-moz-focus-inner,
    [type=\"button\"]::-moz-focus-inner,
    [type=\"reset\"]::-moz-focus-inner,
    [type=\"submit\"]::-moz-focus-inner {
        border-style: none;
        padding: 0;
    }
    button:-moz-focusring,
    [type=\"button\"]:-moz-focusring,
    [type=\"reset\"]:-moz-focusring,
    [type=\"submit\"]:-moz-focusring {
        outline: 1px dotted ButtonText;
    }
    fieldset {
        padding: 0.35em 0.75em 0.625em;
    }
    legend {
        box-sizing: border-box;
        color: inherit;
        display: table;
        max-width: 100%;
        padding: 0;
        white-space: normal;
    }
    progress {
        vertical-align: baseline;
    }
    textarea {
        overflow: auto;
    }
    [type=\"checkbox\"],
    [type=\"radio\"] {
        box-sizing: border-box;
        padding: 0;
    }
    [type=\"number\"]::-webkit-inner-spin-button,
    [type=\"number\"]::-webkit-outer-spin-button {
        height: auto;
    }
    [type=\"search\"] {
        -webkit-appearance: textfield;
        outline-offset: -2px;
    }
    [type=\"search\"]::-webkit-search-decoration {
        -webkit-appearance: none;
    }
    ::-webkit-file-upload-button {
        -webkit-appearance: button;
        font: inherit;
    }
    details {
        display: block;
    }
    summary {
        display: list-item;
    }

    ${sharedNormalization};
`;
