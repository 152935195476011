import { ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Utility for combining classnames and ensuring tailwind styles are properly overwritten.
 *
 * This is similar to `classnames` / `clsx` (uses `clsx` internally). This means you can pass styles in any of the following ways
 *
 * ```tsx
 * className={cn('text-center text-blue-500 text-lg font-bold', className, {'border-primary-500': someBoolean })}
 * ```
 *
 * The `tw-merge` package is used to ensure that tailwind styles are properly merged.
 *
 * @see https://www.npmjs.com/package/clsx
 * @see https://www.npmjs.com/package/tailwind-merge
 *
 * @param inputs Classnames or conditional class objects
 * @returns Combined classnames
 */
export const cn = (...inputs: ClassValue[]) => {
    return twMerge(clsx(inputs));
};
