import { type CleanTheme } from 'src/theme/themeType';
import { css } from 'styled-components';
import { Replace } from 'src/utils';

export type maxMediaOptions = Replace<keyof Pick<CleanTheme['breakpoints'], 'xsMax' | 'smMax' | 'mdMax'>, 'Max', ''>;
export type minMediaOptions = Replace<keyof Pick<CleanTheme['breakpoints'], 'smMin' | 'mdMin' | 'lgMin'>, 'Min', ''>;

/**
 * Available options are `xs` (768) `sm` (1023) `md` (1248)
 */
export const maxMediaQuery = (size: maxMediaOptions, CSS?: ReturnType<typeof css>) => {
    const key = (size + 'Max') as keyof CleanTheme['breakpoints'];
    return css`
        @media (max-width: ${({ theme }) => theme.breakpoints[key]}) {
            ${CSS}
        }
    `;
};

/**
 * Available options are `xs` (768) `sm` (1023) `md` (1248)
 */
export const smallerScreenThan = maxMediaQuery;

/**
 * Available options are `sm` (769) `md` (1024) `lg` (1249)
 */
export const minMediaQuery = (size: minMediaOptions, CSS?: ReturnType<typeof css>) => {
    const key = (size + 'Min') as keyof CleanTheme['breakpoints'];
    return css`
        @media (min-width: ${({ theme }) => theme.breakpoints[key]}) {
            ${CSS}
        }
    `;
};

/**
 * Available options are `sm` (769) `md` (1024) `lg` (1249)
 */
export const largerScreenThan = minMediaQuery;
