import { css } from 'styled-components';
import { type ExpandNestedObjectToStringUnion, type Replace, type Theme } from 'src/types';
import { objectStringReduction } from 'src/utils';
import { smallerScreenThan } from 'src/breakpoints';
import { type TypographySpec } from 'src/theme/themeType';

type RootOptions = ExpandNestedObjectToStringUnion<Theme['typography']>;

type RemovedFamily = Replace<RootOptions, '.family', ''>;
type RemovedHeight = Replace<RemovedFamily, '.height', ''>;
type RemovedSize = Replace<RemovedHeight, '.size', ''>;
type RemovedSpacing = Replace<RemovedSize, '.spacing', ''>;
type RemovedStyle = Replace<RemovedSpacing, '.style', ''>;
type RemovedTransform = Replace<RemovedStyle, '.transform', ''>;
type RemovedWeight = Replace<RemovedTransform, '.weight', ''>;

export type TypographySpecKeys = RemovedWeight;

const typographySpecMixin = (typographySpec: TypographySpec) => css`
    font-family: ${typographySpec.family};
    font-size: ${typographySpec.size};
    font-style: ${typographySpec.style};
    font-weight: ${typographySpec.weight};
    letter-spacing: ${typographySpec.spacing};
    line-height: ${typographySpec.height};
    ${typographySpec.transform &&
    css`
        text-transform: ${typographySpec.transform};
    `}
`;

export const textStylesMixin = ({
    $forceMobile,
    $typographySpecKey,
    theme,
}: {
    /** If undefined, handle mobile normally. If false, don't show mobile. If true, show mobile.  */
    $forceMobile?: boolean;
    $typographySpecKey: TypographySpecKeys;
    theme: Theme;
}) => {
    const typographySpec: TypographySpec = objectStringReduction(theme.typography, $typographySpecKey);

    /* @ts-expect-error It's an unofficial property, tests guarantee it's still valid. */
    const mobileSpec = typographySpec.mobile;

    if ($forceMobile) {
        return css`
            ${typographySpecMixin(mobileSpec)}
        `;
    }

    return css`
        ${typographySpecMixin(typographySpec)}

        ${Boolean(mobileSpec) && smallerScreenThan('xs', typographySpecMixin(mobileSpec))}
    `;
};
