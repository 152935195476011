import { BasicStyledComponentsProps } from '../utils/factories';
import { GenericCSSMixinProps, genericCSSMixins } from 'src/mixins';
import { HTMLProps } from 'react';
import { SpinnerIconExtraSmall, SpinnerIconSmall } from 'src/icons/v1/spinnerIcon';
import styled, { keyframes } from 'styled-components';

export type Props = Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref' | 'size'> &
    BasicStyledComponentsProps & {
        /**
         * Color of the icon
         *
         * @default inverse
         */
        color?: 'primary.default' | 'disabled' | 'inverse' | 'inherit';
        /**
         * Size of the icon to use - small === true uses SpinnerIconExtraSmall - SpinnerIconSmall is default
         *
         * @default false
         */
        small?: boolean;
    };

export const LoadingSpinner = ({ color = 'inverse', small, ...rest }: Props) => {
    const Spinner = small ? ExtraSmallSpinner : SmallSpinner;

    return (
        <LoadingSpinnerContainer
            $small={small}
            data-testid="loading-spinner-animation"
            {...rest}
        >
            <Spinner color={color} />
        </LoadingSpinnerContainer>
    );
};

LoadingSpinner.defaultTestId = 'loading-spinner-animation';

const spinAnimation = keyframes`
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
`;

export const LoadingSpinnerContainer = styled.div<{ $small?: boolean } & GenericCSSMixinProps>`
    ${genericCSSMixins};
    height: ${({ $small }) => ($small ? 14 : 20)}px;
    width: ${({ $small }) => ($small ? 14 : 20)}px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`;

export const SmallSpinner = styled(SpinnerIconSmall)`
    animation: ${spinAnimation} 1s infinite linear;
`;

export const ExtraSmallSpinner = styled(SpinnerIconExtraSmall)`
    animation: ${spinAnimation} 1s infinite linear;
`;
