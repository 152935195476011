import { AnyIcon } from 'src/icons/v1/anyIcon';
import { BaseButton, ButtonLoading, LoadingProp } from './baseButton';
import styled, { css } from 'styled-components';

export const SecondaryButton = styled(BaseButton)<LoadingProp>`
    ${({ $loading, theme }) =>
        $loading
            ? css`
                  background-color: ${theme.button.secondary.pressed.color.background};
                  color: ${theme.button.secondary.pressed.color.background};
                  outline-color: ${theme.button.secondary.pressed.color.border};

                  ${ButtonLoading} {
                      color: ${theme.button.secondary.color.text};
                  }
              `
            : css`
                  color: ${theme.button.secondary.color.text};
                  background-color: ${theme.button.secondary.color.background};
                  outline-color: ${theme.button.secondary.color.border};

                  &:not(:hover):not(:active):not(:disabled):focus-visible {
                      background-color: ${theme.button.secondary.focused.color.background};
                      color: ${theme.color.text.inverse};
                      outline-color: ${theme.button.secondary.focused.color.background};
                  }

                  &:not(:disabled):active {
                      background-color: ${theme.button.secondary.pressed.color.background};
                      outline-color: ${theme.button.secondary.pressed.color.border};
                      color: ${theme.button.secondary.hover.color.text};
                  }

                  &:not(:disabled):not(:active):hover {
                      background-color: ${theme.button.secondary.hover.color.background};
                      outline-color: ${theme.button.secondary.hover.color.border};
                      color: ${theme.button.secondary.hover.color.text};
                  }

                  &:disabled {
                      outline-color: ${theme.color.border.disabled};
                  }

                  &:disabled > ${AnyIcon} {
                      color: ${theme.color.icon.disabled};
                  }

                  &:not(:disabled) > ${AnyIcon} {
                      color: ${theme.color.icon.primary.default};
                  }

                  &:not(:disabled):focus-visible > ${AnyIcon} {
                      color: ${theme.color.icon.inverse};
                  }
              `}
`;
