import { genericCSSMixins } from 'src/mixins';
import { LoadingSpinner } from 'src/loadingSpinner/LoadingSpinner';
import { outlineFocusVisible } from 'src/css/outlineFocusVisible';
import { specificTransition } from 'src/utils';
import { textStylesMixin } from 'src/typography/textStylesMixin';
import styled, { css } from 'styled-components';

export const ButtonLoading = styled(LoadingSpinner)<{ small?: boolean }>`
    position: absolute;
    top: calc(50% - ${({ small }) => (small ? 7 : 10)}px);
    left: calc(50% - ${({ small }) => (small ? 7 : 10)}px);
`;

export type LoadingProp = { $loading?: boolean };

export const BaseButton = styled.button<{ $location?: string; $small?: boolean } & LoadingProp>`
    ${genericCSSMixins};
    ${specificTransition('outline-color', 'color', 'background-color')};
    border: none;
    outline-width: 1px;
    outline-style: solid;
    letter-spacing: 1px;
    align-items: center;
    justify-content: center;
    display: flex;
    position: relative;
    white-space: nowrap;
    width: min-content;
    height: min-content;

    ${({ $loading, disabled }) =>
        !$loading &&
        !disabled &&
        css`
            cursor: pointer;
        `}

    ${({ $small, theme }) =>
        $small
            ? css`
                  ${textStylesMixin({ $typographySpecKey: 'button.200', theme })}
                  /* One pixel less than design since it doesn't account for outline there */
                  padding: 11.5px 15px;
                  gap: 4px;
              `
            : css`
                  ${textStylesMixin({ $typographySpecKey: 'button.100', theme })}
                  /* One pixel less than design since it doesn't account for outline there */
                  padding: 15px 23px;
                  gap: 6px;
              `}

    ${({ theme }) => css`
        border-radius: ${theme.button.border.radius};

        &:disabled {
            color: ${theme.color.text.disabled};
            background-color: ${theme.color.background.disabled};
        }

        &:focus-visible::after {
            inset: 0;
            position: absolute;
            content: ' ';
            border-radius: ${theme.button.border.radius};
            ${outlineFocusVisible};
            outline-offset: 2px;
        }
    `}
`;
