import { ReactNode } from 'react';
import { smallerScreenThan } from 'src/breakpoints';
import styled, { css } from 'styled-components';

type Props = {
    /**
     * If true, will use the larger max width of 1648px instead of 1248px.
     */
    XL?: boolean;
    children: ReactNode;
    className?: string;
};

/**
 * Component for providing a page level grid. It includes a small bit of CSS so that even if you don't take advantage of the grid, it won't break everything. Provides page maxwidth / centering.
 *
 * While its difficult to develop right on top of it, it provides the far more useful feature of debugging said grids using the internal `GridHighlighter`. You can see these highlights by going into the developer console in the dev tools on the browser and typing `window.hui.enableGridHighlights(true)`.
 *
 * `GridHighlighter` is automatically included in the `HammerUIProvider`.
 */
export const PageGrid = ({ children, className, XL }: Props) => {
    return (
        <GridContainer
            $XL={XL}
            className={className}
        >
            {children}
        </GridContainer>
    );
};

/**
 * Useful for following the grid when you have full page-width access. If you don't have full pagewidth access, consider `useSubGridCSS`.
 *
 * ```css
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);

   (screen < xsMin) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(4, 1fr);
    }
 * ```
 *
 * Pairs well with a `grid-template-areas`.
 */
export const baseGrid_CSS = css`
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);

    ${smallerScreenThan(
        'xs',
        css`
            grid-column-gap: 16px;
            grid-template-columns: repeat(4, 1fr);
        `
    )}
`;

/**
 * Useful for following the grid when you are restricted to some form of subgrid.
 * For example, if you had "10 columns" since the left and right one are omitted
 * / your component is centered, You'd only want the 10 columns instead of 12.
 *
 * ```css
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(cols, 1fr);

   (screen < xsMin) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(mobileCols, 1fr);
    }
 * ```
 *
 * Pairs well with a `grid-template-areas`.
 */
export const variableColumnGrid_CSS = (cols: number, mobileCols: number = 4) => {
    if (process.env.NODE_ENV !== 'production') {
        if (cols < 1) {
            throw new Error('Cannot have less than 1 column');
        }
        if (cols > 12) {
            throw new Error('Cannot have more than 12 columns');
        }
        if (mobileCols && mobileCols < 1) {
            throw new Error('Cannot have less than 1 column (mobile)');
        }
        if (mobileCols && mobileCols > 4) {
            throw new Error('Cannot have more than 4 columns (mobile)');
        }
    }

    return css`
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(${cols}, 1fr);

        ${smallerScreenThan(
            'xs',
            css`
                grid-column-gap: 16px;
                grid-template-columns: repeat(${mobileCols}, 1fr);
            `
        )}
    `;
};

/**
 * Useful for defining page grid styles including pagewidth / autocentering.
 *
 * ```css
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 24px;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.breakpoints.mdMax};

   (screen < xsMin) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(4, 1fr);
        margins: 0 16px;

        > * {
            grid-column: span 4;
        }
    }
 * ```
 *
 * Pairs well with a `grid-template-areas`.
 */
export const pageGrid_CSS = css`
    ${baseGrid_CSS}
    padding: 0 24px;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.breakpoints.mdMax};

    ${smallerScreenThan(
        'xs',
        css`
            margins: 0 16px;

            > * {
                grid-column: span 4;
            }
        `
    )}

    @media print {
        padding: 0;
    }
`;

/**
 * Useful for defining page grid styles including pagewidth / autocentering.
 *
 * This size is XL, meaning, it's for pages like a search page.
 * Check with your designer to see if you need this increased page width.
 *
 * ```css
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(12, 1fr);
    padding: 0 24px;
    margin: 0 auto;
    max-width: ${({ theme }) => theme.breakpoints.lgMax};

   (screen < xsMin) {
        grid-column-gap: 16px;
        grid-template-columns: repeat(4, 1fr);
        margins: 0 16px;

        > * {
            grid-column: span 4;
        }
    }
 * ```
 *
 * Pairs well with a `grid-template-areas`.
 */
export const pageGrid_XL_CSS = css`
    ${pageGrid_CSS}
    max-width: ${({ theme }) => theme.breakpoints.lgMax};
`;

export const GridContainer = styled.div<{ $XL?: boolean }>`
    ${pageGrid_CSS}

    ${({ $XL, theme }) =>
        $XL &&
        css`
            max-width: ${theme.breakpoints.lgMax};
        `}

    > * {
        grid-column: span 12;
    }

    ${smallerScreenThan(
        'xs',
        css`
            > * {
                grid-column: span 4;
            }
        `
    )}
`;
