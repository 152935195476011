import { AnyIcon } from 'src/icons/anyIcon';
import { BasicStyledComponentsProps } from 'src/utils';
import { ButtonLoading } from './baseButton';
import { cn } from '@liveauctioneers/hammer-ui-theme/cn';
import { forwardRef, HTMLProps, MouseEventHandler, useCallback, useMemo } from 'react';
import { GenericCSSMixinProps } from 'src/mixins';
import { KnownTarget } from 'styled-components/dist/types';
import { PrimaryAltButton } from './primaryAltButton';
import { PrimaryButton } from './primaryButton';
import { SecondaryAltButton } from './secondaryAltButton';
import { SecondaryButton } from './secondaryButton';
import { TertiaryButton } from './tertiaryButton';

type ButtonIconProps = {
    /**
     * Optional icon to show alongside the component.
     *
     * Usage:
     * ```tsx
     * <Button icon={HeartIconSmall} />
     * ```
     *
     * Notes:
     * - For a small button, the size of an icon must be `ExtraSmall`
     * - For a regular button, the size of an icon must be `Small`
     */
    icon?: AnyIcon;
    /**
     * How to pass a color prop to an icon.
     * The colors are not typechecked.
     *
     * Usage:
     * ```tsx
     * <Button icon={HeartIconSmall} iconColor="default" />
     * ```
     */
    iconColor?: string;
    /**
     * Optional location for the icon's rendering. Requires `icon` prop to do anything
     *
     * Usage:
     * ```tsx
     * <Button icon={HeartIconSmall} iconPlacement="right" />
     * ```
     *
     * @default 'left'
     */
    iconPlacement?: 'left' | 'right';
};

type BaseProps = {
    /**
     * Optional loading state trigger. Disables button and adds a loading state.
     *
     * Usage:
     * ```tsx
     * <Button loading />
     * ```
     *
     * @default false
     */
    loading?: boolean;
    /**
     * Optional small button style. Changes `icon` requirements.
     *
     * Usage:
     * ```tsx
     * <Button small />
     * ```
     *
     * @default false
     */
    small?: boolean;
    type?: 'button' | 'submit' | 'reset';
    /**
     * Optional style for button.
     *
     * Usage:
     * ```tsx
     * <Button small />
     * ```
     *
     * @default 'primary'
     */
    variant?: 'primary' | 'secondary' | 'tertiary' | 'primaryAlt' | 'secondaryAlt';
} & Partial<Omit<HTMLProps<HTMLButtonElement>, 'type'>> & { forwardedAs?: KnownTarget } & BasicStyledComponentsProps &
    GenericCSSMixinProps;

type Props = BaseProps & ButtonIconProps;

/**
 * A wonderful HUI button. Inherits all props from a normal HTML button.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 */
export const Button = forwardRef<HTMLButtonElement, Props>(
    (
        {
            children,
            disabled = false,
            icon: Icon,
            iconColor,
            iconPlacement = 'left',
            loading = false,
            onClick: onClickProp,
            small,
            variant,
            ...rest
        }: Props,
        ref
    ) => {
        const ButtonComponent = useMemo(() => {
            switch (variant) {
                case 'secondary':
                    return SecondaryButton;
                case 'tertiary':
                    return TertiaryButton;
                case 'primaryAlt':
                    return PrimaryAltButton;
                case 'secondaryAlt':
                    return SecondaryAltButton;
                default:
                    return PrimaryButton;
            }
        }, [variant]);

        const onClick: MouseEventHandler<HTMLButtonElement> = useCallback(
            (e) => {
                if (!disabled && !loading && onClickProp) {
                    onClickProp(e);
                }
            },
            [disabled, loading, onClickProp]
        );

        return (
            <ButtonComponent
                $loading={loading}
                $small={small}
                aria-busy={loading}
                aria-live="polite"
                disabled={disabled && !loading}
                onClick={onClick}
                ref={ref}
                {...rest}
            >
                {Icon && iconPlacement === 'left' && (
                    <Icon
                        className={cn('inline', {
                            'my-[-3px]': small,
                            'my-[-5px]': !small,
                        })}
                        color={iconColor as any}
                        size="sm"
                    />
                )}
                {children}
                {Icon && iconPlacement === 'right' && (
                    <Icon
                        className={cn('inline', {
                            'my-[-3px]': small,
                            'my-[-5px]': !small,
                        })}
                        color={iconColor as any}
                        size="sm"
                    />
                )}
                {loading && (
                    <ButtonLoading
                        color="inherit"
                        small={small}
                    />
                )}
            </ButtonComponent>
        );
    }
);
