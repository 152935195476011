type RecursiveRecord = any | { [key: string]: any | RecursiveRecord };

/**
 * Create as you assign.
 *
 * EG: `CAYA(theme, 'input.border.radius', 0) -> theme.input.border.radius = 0`
 */
export const CAYA = <Path extends string>(obj: object, pathRaw: Path, value: any): void => {
    let objTree: RecursiveRecord = obj;
    const path = pathRaw.trim().split('.');

    for (let i = 0; i < path.length; i += 1) {
        if (i !== path.length - 1) {
            if (!Boolean(objTree[path[i]])) {
                objTree[path[i]] = {};
            }
            objTree = objTree[path[i]];
        } else {
            objTree[path[i]] = value;
        }
    }
};
