import { createContext, ReactNode, useCallback, useContext } from 'react';

const LinkContext = createContext<(href: string) => void>(() => {
    throw new Error(
        'No onLinkClick found. Did you forget to add onLinkClick to HammerUIProvider (or the separate LinkProvider)?'
    );
});

type Props = {
    children: ReactNode;
    /** Callback when link is clicked */
    onLinkClick: (href: string) => void;
};

/**
 * A provider that powers all links below the `LinkProvider`.
 * Since we don't use standardized routing across apps, this is necessary to make links work as you'd expect.
 *
 * You can overrwrite this context by providing a more specific one:
 * A global `LinkProvider` can be overwritten by a `LinkProvider` for a specific component.
 *
 * For example, you might have a default `onLinkClick` in the `HammerUIProvider`
 * ```tsx
 * <HammerUIProvider onLinkClick={handleLinkClick}>
 * // ...
 * ```
 *
 * But if you need to have specific onLinkClick behavior for a component, you can overwrite it:
 * ```tsx
 * const onLinkClick = useOnLinkClick();
 * // you can use the above hook to get the "parent" onLinkClick
 * <LinkProvider onLinkClick={handleLinkClick}>
 * <ComponentWithLinks />
 * </LinkProvider>
 * ```
 */
export const LinkProvider = ({ children, onLinkClick }: Props) => {
    return <LinkContext.Provider value={onLinkClick}>{children}</LinkContext.Provider>;
};

export const useOnLinkClick = () => useContext(LinkContext);

export const usePreventDefaultLinkOnClick = () => {
    const onLinkClick = useOnLinkClick();

    return useCallback(
        (href: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
            event.preventDefault();
            onLinkClick(href);
        },
        [onLinkClick]
    );
};
