import {
    BasicDefinition,
    BasicStyledComponentsProps,
    ExpandNestedObjectToStringUnion,
    IGNORECHECKSType,
} from 'src/utils';
import { GenericCSSMixinProps } from 'src/mixins';
import { ReactNode, SVGProps } from 'react';
import { type Theme } from 'src/theme/themeType';

export type AllIconColorOptions = ExpandNestedObjectToStringUnion<Theme['color']['icon']>;

export type TrueIconProps = Omit<SVGProps<SVGSVGElement>, 'ref' | 'as' | 'color' | 'children'> &
    IGNORECHECKSType &
    BasicStyledComponentsProps &
    GenericCSSMixinProps & {
        /**
         * @deprecated Add colors via `text-icon-*` + `inherit` (no color at all)
         *
         * Recommended method to apply colors. If you're applying colors any other way, you probably shouldn't be doing it at all. Please talk to a designer.
         *
         * Default is `'inherit'` (css) `currentColor` (steal color from `color: ...` css).
         */
        color?: AllIconColorOptions | 'inherit';
        /**
         * Adds a hover color only to the button itself.
         */
        hoverColor?: AllIconColorOptions;
    };

export type IconDefinition = BasicDefinition & {
    /**
     * Allows you to add children to icons based on color.
     * This is useful for static elements that add contrast,
     * like the black background of the WarningIcon.
     */
    conditionallyAddChildrenByColor?: (color: AllIconColorOptions | 'inherit') => ReactNode;
    /**
     * The key for height and width of icon based on the following mapping
     *
     * ```
     * 'ExtraSmall' _ '14px'
     * 'Small' ______ '20px'
     * 'Medium' _____ '30px'
     * 'Large' ______ '44px'
     * ```
     */
    size: 'ExtraSmall' | 'Small' | 'Medium' | 'Large';
    /**
     * SVG component `<svg>` WITHOUT STYLES. Pass classnames as normal strings if necessary.
     */
    svg: ReactNode;
    /**
     * Which SVG elements to apply the color to. IE `['path']`.
     */
    svgElements: ('path' | 'circle' | 'rect' | 'polygon' | 'line' | 'polyline' | 'ellipse')[];
};

export const iconSizesToPixels: { [key in IconDefinition['size']]: number } = {
    ExtraSmall: 14,
    Large: 44,
    Medium: 30,
    Small: 20,
};
