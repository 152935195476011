import { AllIconColorOptions, IconDefinition, iconSizesToPixels, TrueIconProps } from './iconDefinition';
import { AnyIcon } from './anyIcon';
import { camelCaseToKebabCase, checkColor, objectStringReduction } from 'src/utils';
import { forwardRef, ForwardRefExoticComponent } from 'react';
import { genericCSSMixins } from 'src/mixins';
import styled, { css } from 'styled-components';

//
// This file should only be used for custom icons / icons not inside Phosphor. See `tsup.config.ts` to add a Phosphor Icon.
//

type StyledProps = TrueIconProps & {
    $color: AllIconColorOptions | 'inherit';
    $hoverColor?: AllIconColorOptions;
    $px: number;
    $svgElements: IconDefinition['svgElements'];
};

export type IconComponent = ForwardRefExoticComponent<TrueIconProps> & {
    __version?: 'v1' | 'v2';
    defaultTestId: string;
    size: IconDefinition['size'];
};

const StyledIcon = styled(AnyIcon)<StyledProps>`
    ${genericCSSMixins};
    ${({ $color, $hoverColor, $px, $svgElements, theme }) => css`
        width: ${$px}px;
        height: ${$px}px;
        min-width: ${$px}px;
        min-height: ${$px}px;
        max-width: ${$px}px;
        max-height: ${$px}px;

        ${$svgElements.join(',')} {
            fill: ${$color === 'inherit' ? 'currentColor' : objectStringReduction(theme.color.icon, $color)};
        }

        ${$hoverColor &&
        css`
            &:hover {
                cursor: pointer;
                ${$svgElements.join(',')} {
                    fill: ${objectStringReduction(theme.color.icon, $hoverColor)};
                }
            }
        `}
    `}
`;

export const allIconColorOptions: AllIconColorOptions[] = [
    'active',
    'critical',
    'decorative.alt',
    'decorative.default',
    'decorative.light',
    'default',
    'disabled',
    'hover',
    'info',
    'inverse',
    'primary.alt.active',
    'primary.alt.default',
    'primary.alt.hover',
    'primary.default',
    'success',
    'warning.default',
    'warning.light',
];

const inherit = ['inherit'];

export function iconFactory(ic: IconDefinition) {
    const kebabTestId = camelCaseToKebabCase(ic.name);

    const IconFC = forwardRef<SVGSVGElement, TrueIconProps>(({ color, hoverColor, IGNORECHECKS, ...rest }, ref) => {
        const px = iconSizesToPixels[ic.size];
        const chosenColor = checkColor(allIconColorOptions, ic.name, color, 'icon', IGNORECHECKS, inherit) ?? 'inherit';

        return (
            <StyledIcon
                $color={chosenColor}
                $hoverColor={checkColor(allIconColorOptions, ic.name, hoverColor, 'icon', IGNORECHECKS)}
                $px={px}
                $svgElements={ic.svgElements}
                data-testid={kebabTestId}
                fill="none"
                height={px}
                ref={ref}
                role="none"
                viewBox={`0 0 ${px} ${px}`}
                width={px}
                // Figma exports px x px icon with 0 0 px px viewbox.
                xmlns="http://www.w3.org/2000/svg"
                {...rest}
            >
                {ic.conditionallyAddChildrenByColor ? ic.conditionallyAddChildrenByColor(chosenColor) : null}
                {ic.svg}
            </StyledIcon>
        );
    });

    const Icon = IconFC as unknown as IconComponent;

    Icon.displayName = ic.name;
    Icon.defaultTestId = kebabTestId;
    Icon.size = ic.size;
    Icon.__version = 'v1';

    return Icon;
}

export { type AllIconColorOptions };
